import React, { useState, useEffect, useRef, useMemo } from "react"
import { Typography } from "components/typography"
import ProgressBar from "components/survey/progress-bar"
import axios from "axios"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import Grid from "@mui/material/Grid"
import { debounce } from "@mui/material/utils"
import * as styles from "./style.module.scss"
import { navigate } from "gatsby"
import { createTheme, Stack } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useStore } from "react-redux"
import { apis as API } from "@sog/sdk"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import store from "state/store"
import { Select, MenuItem } from "@mui/material"
import ProvidersListView from "components/survey/providers-list-view"

const UpdateYourProvider = props => {
  const state = store.getState()

  const properties = props.location.state.properties
  const currentIndex = props.location.state.currentIndex

  const didSelectLoanProvider = (value: any) => {
    // DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_PROVIDER_SELECTED, { id: value.id, productCategory: "CREDIT_CARD" })
    // setLoanProvider(loanProvider === null ? value : null)
    // const newProviders = { ...providers }
    // newProviders[currentIndex] = value
    // setProviders(newProviders)

    // const newOpen = { ...open }
    // newOpen[currentIndex] = false
    // setOpen(newOpen)

    // const dataCollectorProviders = {}

    // if (!multipleProvider) {
    //   jsonInput.map((x, i) => {
    //     x.provider = { "id": value.id, "name": value.name }
    //     dataCollectorProviders["P" + (i + 1)] = x.provider.id
    //   })
    //   DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROVIDER_SUBMITTED, { "surveyId": state.survey.surveyId, ...dataCollectorProviders })
    //   navigate("/home-loans/about-your-loan", {
    //     state: {
    //       jsonInput: jsonInput,
    //       progress: progress + 1,
    //     },
    //   })
    // } else {
    //   dataCollectorProviders["P" + (currentIndex + 1)] = value.id
    //   DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROVIDER_SELECTED, dataCollectorProviders)
    // }
    properties[currentIndex].provider = { id: value.id, name: value.name }
    navigate("/user/update-your-home-loan", {
      state: {
        properties: properties,
        currentIndex: currentIndex,
      },
    })
  }

  return (
    <div className="page-container">
      <div className="survey-container">
        <Typography.H3 comp="hl-survey">
          Update your provider
          <ProvidersListView list={state.main.hlProvider} isSelectYourBanks={false} selectedProvider={null} didSelectProviderCallback={didSelectLoanProvider} />
        </Typography.H3>
      </div>
    </div>
  )
}

export default UpdateYourProvider
