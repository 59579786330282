import * as styles from "./style.module.scss"
import React, { useEffect, useState } from "react"
import { Typography } from "components/typography"
import { CircularProgress, Stack, useMediaQuery } from "@mui/material"
import { apis as API } from "@sog/sdk"
import * as Clickable from "components/clickable"
import { navigate } from "gatsby"
import { useAuth } from "state/AuthContext"

type BaseEventSummaryType = {
  dateCreated: string,
  status: string,
  info: {
    providerId: string,
    providerName: string,
    [key: string]: any
  },
  [key: string]: any
}

type FulFilledEventSummaryType = BaseEventSummaryType & {
  status: "FULFILLED",
  info: {
    estimatedSavings: number
  }
}

type NoOfferEventSummaryType = BaseEventSummaryType & {
  status: "NO_OFFER",
  info: { }
}

type ExpiredEventSummaryType = BaseEventSummaryType & {
  status: "EXPIRED",
  info: { }
}

export type EventSummaryType = (
  FulFilledEventSummaryType |
  NoOfferEventSummaryType |
  ExpiredEventSummaryType
)

const eventTypeNameMap: {[key in EventSummaryType["status"]]: string} = {
  "FULFILLED": "New Home Loan Deal",
  "NO_OFFER": "No Offer Received",
  "EXPIRED": "Offer Expired"
}

export const EventSummary = ({eventSummary}: {eventSummary: EventSummaryType}) => {
  const content = renderEventSummaryContent(eventSummary)
  if (!content) {
    return null
  }
  const timestamp = new Date(eventSummary.dateCreated)

  return <div>
    <div className={styles.eventSummary}>
      <div className={styles.header}>
        <Typography.P className={styles.title}>
          {eventTypeNameMap[eventSummary.status] || "Event Summary"}
        </Typography.P>
        {!isNaN(timestamp.valueOf()) && <Typography.P className={styles.date}>
          {timestamp.toLocaleDateString('AU')}
        </Typography.P>}
      </div>
      {content}
    </div>
  </div>
}

const renderEventSummaryContent = (eventSummary: EventSummaryType) => {
  // TODO: refinance event summaries
  switch (eventSummary.status) {
    case "EXPIRED":
      return <ExpiredEventSummary eventSummary={eventSummary} />
    case "NO_OFFER":
      return <NoOfferEventSummary eventSummary={eventSummary} />
    case "FULFILLED":
      return <FulfilledEventSummary eventSummary={eventSummary} />
    default:
      return null
  }
}

const FulfilledEventSummary = ({eventSummary}: {eventSummary: FulFilledEventSummaryType}) => {
  return <>
    <Typography.P>
      You received improved home loan pricing from {eventSummary.info?.providerName}, saving you an estimated ${eventSummary.info?.estimatedSavings?.toLocaleString('en-AU')} over 5 years.​
    </Typography.P>
  </>
}

const NoOfferEventSummary = ({eventSummary}: {eventSummary: NoOfferEventSummaryType}) => {
  return <>
    <Typography.P>
     {eventSummary.info?.providerName} declined to offer you a better home loan deal.​
    </Typography.P>
  </>
}

const ExpiredEventSummary = ({eventSummary}: {eventSummary: ExpiredEventSummaryType}) => {
  return <>
    <Typography.P>
     {eventSummary.info?.providerName} offered you a new home loan deal, however you did not accept it.​
    </Typography.P>
  </>
}

const EventHistory = () => {
  const isWide = useMediaQuery("(min-width:768px)")
  const [events, setEvents] = useState<EventSummaryType[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [itemsRemaining, setItemsRemaining] = useState(true)

  const { userDetails, isRefetching, error: authError } = useAuth()
  const userStatus = React.useMemo(
    () => (isRefetching ? "loading" : authError ? "not-permitted" : userDetails ? "permitted" : "loading"),
    [isRefetching, userDetails, authError]
  )

  useEffect(() => {
    if (userDetails) {
      loadUserNotifications()
      /* TEST DATA */
      // setEvents([

      // ])
      // setLoading(false)
      // setItemsRemaining(false)
      /* END TEST */
    }
  }, [userDetails])

  // useEffect(() => {
  //   console.log('notifications:',events)
  // }, [events])

  const onSuccess = (responseJson: any) => {
    if (responseJson) {
      const items = responseJson.items || []
      if (responseJson.count <= (items.length + events.length)) {
        setItemsRemaining(false)
      }
      setEvents([...events, ...items.map((n: any) => (n as EventSummaryType))])
      setLoading(false)
    }
  }
  const onError = (error: any) => {
    // if (error.status === 401) {
    //   navigate("/user/sign-in")
    // }
    setError("An error has occurred. Please reload the page.")
    setLoading(false)
  }

  const loadUserNotifications = () => {
    setLoading(true)
    API.getEventHistory(onSuccess, onError, 10, events.length || 0)
  }

  return <div className="page-container">
    <div style={{ width: isWide ? "480px" : "90%" }}>
      {userStatus !== "permitted" ? <CircularProgress /> : <>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end" marginBottom={"30px"}>
          <Typography.P comp="profile-large-bold" fontColor="#202020">
            History
          </Typography.P>
          <Clickable.Text
            secondary
            onClick={() => navigate("/user/dashboard")}
          >
            <Typography.P comp="hl-medium-small" fontColor="#00AFC5" style={{padding: "2px 6px"}}>
              Back
            </Typography.P>
          </Clickable.Text>
        </Stack>
        {events && events.length > 0 && (
          events.map((s,i) => <EventSummary eventSummary={s} key={i} />)
        )}
        {loading ? <CircularProgress style={{color: "#00AFC5"}} /> : error ? <Typography.P>
          {error}
        </Typography.P> : itemsRemaining ? <Clickable.Text
          style={{ height: "36px" }}
          onClick={() => {!loading && loadUserNotifications()}}
        >
          <Typography.P comp="medium-bold-tight" fontColor="#FFFFFF">
            Load more
          </Typography.P>
        </Clickable.Text> : (!events || events.length === 0) ? <Typography.P>
          No historical event outcomes to display.
        </Typography.P> : null}
      </>}
    </div>
  </div>
}

export default EventHistory