// extracted by mini-css-extract-plugin
export var body = "style-module--body--f5ec0";
export var date = "style-module--date--8e20d";
export var header = "style-module--header--729d3";
export var noBetterDeal = "style-module--noBetterDeal--61fb8";
export var notificationActionButtonsRow = "style-module--notificationActionButtonsRow--6c890";
export var offer = "style-module--offer--67f0a";
export var text = "style-module--text--dabcc";
export var title = "style-module--title--697f0";
export var unread = "style-module--unread--9474c";
export var unreadDot = "style-module--unreadDot--aac62";
export var userNotification = "style-module--userNotification--38263";