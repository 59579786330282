import React from "react"
import { Redirect, Router } from "@reach/router"
import Layout from "components/layout"
import SignIn from "components/user/sign-in"
import SignUp from "components/user/sign-up"
import Dashboard from "components/user/dashboard"
import VerifyOtp from "components/user/verify-otp"
import UpdateYourProperty from "components/user/update-your-property"
import UpdateYourProvider from "components/user/update-your-provider"
import UpdateYourHomeLoan from "components/user/update-your-home-loan"
import Notifications from "components/user/notifications"
import EventHistory from "components/user/event-history"
import PrivateRoute from "components/user/private-route"
import { DataCollector, DataCollectorAction } from "@sog/sdk"

const UserBase = ({
  component: Component,
  loginState = 'any',
  redirectTo = '/',
  ...rest
}: {
  component: (props: any) => React.ReactNode,
  loginState?: 'any' | 'logged-in' | 'logged-out',
  redirectTo?: string,
  [key: string]: any
}) => {
  if (loginState === 'any')
  {
    return <Component {...rest} />
  }
  return <PrivateRoute
    redirectTo={redirectTo}
    component={Component}
    loggedOutOnly={loginState === 'logged-out'}
    {...rest}
  />
}

export default function User(props: any) {

  return (
    <Layout title="User | Stay or Go" description="User" image="/video/StayOrGo_Animation_01.jpf" urlSlug="user">
      <Router basepath="/user" primary={false}>
        <Redirect from="/user" to="/user/dashboard" default noThrow replace />
        {/* sign-in base */}
        <UserBase path="/sign-in" component={(p) => <SignIn
          signupPagePath="/user/sign-up/"
          onSuccessPagePath="/user/sign-in/verify-email"
          {...p}
        />} loginState={'logged-out'} redirectTo="/user/dashboard" />
        {/* sign-in verify email */}
        <UserBase path="/sign-in/verify-email" component={(p) => <VerifyOtp
          onSuccessPagePath="/user/dashboard"
          baseRedirectPagePath="/user/sign-in"
          previousPagePath="/user/sign-in"
          {...p}
        />} loginState={'logged-out'} redirectTo="/user/dashboard" />
        {/* sign-up base */}
        <UserBase path="/sign-up" component={(p) => <SignUp
          signinPagePath="/user/sign-in"
          onSuccessPagePath="/user/sign-up/verify-email"
          onSuccessCallback={(data, responseJson) => {
            DataCollector.getInstance().addAction(DataCollectorAction.USER_SIGN_UP, {
              firstName: data.user_info.first_name,
              lastName: data.user_info.last_name,
              email: data.email,
              mobile: data.mobile_number
            })
          }}
          {...p}
        />} loginState={'logged-out'} redirectTo="/user/dashboard" />
        {/* sign-up verify email */}
        <UserBase path="/sign-up/verify-email" component={(p) => <VerifyOtp
          onSuccessPagePath="/user/sign-up/verify-mobile"
          isSignUp
          baseRedirectPagePath="/user/sign-up"
          previousPagePath="/user/sign-up"
          {...p}
        />} loginState={'logged-out'} redirectTo="/user/dashboard" />
        {/* sign-up verify mobile */}
        <UserBase path="/sign-up/verify-mobile" component={(p) => <VerifyOtp
          onSuccessPagePath="/user/dashboard"
          isSignUp
          isMobileVerificationPage
          baseRedirectPagePath="/user/sign-up"
          previousPagePath="/user/sign-up/verify-email"
          {...p}
        />} loginState={'logged-out'} redirectTo="/user/dashboard" />
        <UserBase path="/dashboard" component={Dashboard} loginState={'logged-in'} />
        <UserBase path="/notifications" component={Notifications} loginState={'logged-in'} />
        <UserBase path="/history" component={EventHistory} loginState={'logged-in'} />
        <UserBase path="/update-your-property" component={UpdateYourProperty} loginState={'logged-in'} />
        <UserBase path="/update-your-provider" component={UpdateYourProvider} loginState={'logged-in'} />
        <UserBase path="/update-your-home-loan" component={UpdateYourHomeLoan} loginState={'logged-in'} />
      </Router>
    </Layout>
  )
}
