import CloseIcon from "@mui/icons-material/Close"
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import { LazyLoadImage } from "react-lazy-load-image-component"
import * as Clickable from "components/clickable"
import { Typography } from "components/typography"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import store from "state/store"

import React from "react"

const HelpDialog = props => {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      {props.icon ? (
        <Clickable.Text
          style={{ backgroundColor: "inherit" }}
          onClick={() => {
            handleClickOpen()
            if (props.buttonId) {
              DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_HELP_SELECTED, { surveyId: store.getState().survey.surveyId, buttonId: props.buttonId })
            }
          }}
        >
          <LazyLoadImage
            style={{width: "18px", height: "18px"}}
            src={props.icon}
            alt=""
            onClick={() => {
              handleClickOpen()
            }}
          />
        </Clickable.Text>
      ) : props.disclaimer ? (
        <Clickable.Text
          style={{ backgroundColor: "white" }}
          onClick={() => {
            handleClickOpen()
          }}
        >
          <LazyLoadImage
            src={"/images/icon_help_small.png"}
            style={{ marginLeft: "4px", width: "14px", height: "14px" }}
            alt=""
            onClick={() => {
              handleClickOpen()
            }}
          />
        </Clickable.Text>
      ) : (
        <Clickable.Text
          comp={1}
          round
          style={{ width: "25px", height: "25px" }}
          onClick={() => {
            handleClickOpen()
          }}
        >
          ?
        </Clickable.Text>
      )}
      <Dialog
        onClose={() => {
          handleClose()
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div style={{ width: "100%", padding: "5px" }}>
          <IconButton
            aria-label="close"
            style={{ float: "right", color: "#7d7d7d" }}
            onClick={() => {
              handleClose()
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ padding: "0px 30px 30px 30px" }}>
          {props.info && <Typography.P>{props.info}</Typography.P>}
          {props.children}
        </div>
      </Dialog>
    </div>
  )
}

export default HelpDialog
