import * as styles from "./style.module.scss"
import React, { useEffect, useState } from "react"
import { Typography } from "components/typography"
import { CircularProgress, Stack, useMediaQuery } from "@mui/material"
import { apis as API } from "@sog/sdk"
import * as Clickable from "components/clickable"
import { navigate } from "gatsby"
import { useAuth } from "state/AuthContext"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { providerImage } from "components/survey/utils"

type ProductDetail = {
  dateFrom: string,
  dateTo: string,
  id: number,
  indexRate: number,
  indexRateId: string,
  loanPurpose: "OWNER_OCCUPIED" | "INVESTMENT",
  productId: string,
  productName: string,
  providerId: string,
  providerName: string,
  repaymentType: "PRINCIPAL_AND_INTEREST" | "INTEREST_ONLY"
}

type LoanType = {
  expiryDate: string,
  knowRevertRate: boolean,
  product: ProductDetail,
  rate: number,
  rateType: "VARIABLE" | "FIXED"
  revertProduct?: ProductDetail,
  revertRate?: number,
}

type OfferType = {
  cashback: number,
  estimatedSavings: number[][],
  fulfilment: string,
  loans: LoanType[][]
}

type BaseUserNotificationType = {
  created: string,
  viewed: boolean,
  type: string,
  info: {
    providerId: string,
    providerName: string,
    [key: string]: any
  },
  [key: string]: any
}

type InProgressNotificationType = BaseUserNotificationType & {
  type: "IN_PROGRESS",
  info: { expireDate: string }
}

type OverdueNotificationType = BaseUserNotificationType & {
  type: 'OVERDUE'
}

type FulfilledNotificationType = BaseUserNotificationType & {
  type: 'FULFILLED',
  info: { offers: OfferType }
}

type AwaitingCustomerNotificationType = BaseUserNotificationType & {
  type: 'AWAITING_CUSTOMER',
  info: { offers: OfferType }
}

type NoOfferNotificationType = BaseUserNotificationType & {
  type: 'NO_OFFER',
  // TODO: define additional fields for info object
  info: {}
}

type RefinanceNotificationType = BaseUserNotificationType & {
  type: 'REFINANCE'
}

type ExceptionSituationNotificationType = BaseUserNotificationType & {
  type: 'EXCEPTION_SITUATION'
}

type ExceptionDetailsNotificationType = BaseUserNotificationType & {
  type: 'EXCEPTION_DETAILS'
}

export type UserNotificationType = (
  InProgressNotificationType |
  OverdueNotificationType |
  FulfilledNotificationType |
  AwaitingCustomerNotificationType |
  NoOfferNotificationType | 
  RefinanceNotificationType |
  ExceptionSituationNotificationType |
  ExceptionDetailsNotificationType
)

const notificationTypeNameMap: {[key in UserNotificationType["type"]]: string} = {
  "IN_PROGRESS": "In progress",
  "OVERDUE": "Overdue",
  "FULFILLED": "Rate reduction",
  "AWAITING_CUSTOMER": "You have an offer",
  "NO_OFFER": "No better deal",
  "REFINANCE": "Refinancing",
  "EXCEPTION_SITUATION": "Update your loan details",
  "EXCEPTION_DETAILS": "Update your contact details"
}

const RenderOfferSummary = ({
  providerName,
  providerId,
  totalSavings
}: {
  providerName: string,
  providerId: string,
  totalSavings: number
}) => {
  return <div className={styles.offer}>
    <LazyLoadImage src={providerImage(providerId)} style={{height: "37.5px"}} />
    {/* <Typography.P comp="profile-large-medium" fontColor="#696969">
      {providerName}
    </Typography.P> */}
    <Typography.P comp="profile-large-bold" fontColor="#00AFC5">
      ${Math.round(totalSavings).toLocaleString()}
    </Typography.P>
    <Typography.P comp="x-small" fontColor="#696969">
      estimated savings over 5 years
    </Typography.P>
  </div>
}

export const UserNotification = ({notification}: {notification: UserNotificationType}) => {
  const content = renderNotificationContent(notification)
  if (!content) {
    return null
  }
  const notificationType = notification.type
  const timestamp = new Date(notification.created)

  const buttonContent = notificationButtonsContent(notification)

  return <div>
    <div className={styles.userNotification + ((notification.viewed && false) ? "" : (" " + styles.unread))}>
      <div className={styles.header}>
        <Typography.P className={styles.title}>
          {/* {!notification.viewed && <span className={styles.unreadDot}>•</span>} */}
          <span className={styles.text}>
            {notificationTypeNameMap[notificationType] || "Notification"}
          </span>
        </Typography.P>
        {!isNaN(timestamp.valueOf()) && <Typography.P className={styles.date}>
          {timestamp.toLocaleDateString('AU')}
        </Typography.P>}
      </div>
      <div className={styles.body}>
        {content}
      </div>
    </div>
    {!!buttonContent && <div>
      
    </div>}
  </div>
}

const renderNotificationContent = (notification: UserNotificationType) => {
  // commented-out items are TODO
  switch (notification.type) {
    case "IN_PROGRESS":
      return <InProgressNotification notification={notification} />
    case "OVERDUE":
      return <OverdueNotification notification={notification} />
    case "FULFILLED":
      return <FulfilledNotification notification={notification} />
    // case "AWAITING_CUSTOMER":
    //   return <AwaitingCustomerNotification notification={notification} />
    case "NO_OFFER":
      return <NoOfferNotification notification={notification} />
    // case "REFINANCE":
    //   return <RefinanceNotification notification={notification} />
    // case "EXCEPTION_SITUATION":
    //   return <ExceptionSituationNotification notification={notification} />
    // case "EXCEPTION_DETAILS":
    //   return <ExceptionDetailsNotification notification={notification} />
    default:
      return null
  }
}

const notificationButtonsContent: ((notification: UserNotificationType) => {
  text: string,
  onClick: () => any
} | null) = (notification: UserNotificationType) => {
  // TODO: add buttons for specific types of Notification
  switch (notification.type) {
    
    default:
      return null
  }
}

const InProgressNotification = ({notification}: {notification: InProgressNotificationType}) => {
  const expireDate = new Date(notification.info.expireDate)
  return <>
    <LazyLoadImage src={providerImage(notification.info.providerId)} style={{height: "37.5px", marginBottom: "10px"}} />
    <Typography.P>
      {notification.info.providerName} is in the process of reviewing your home loan deal and {!isNaN(expireDate.valueOf())
        ? `has until ${expireDate.toLocaleDateString('AU')} to respond.`
        : "will respond in the coming days."
      }
    </Typography.P>
  </>
}

const OverdueNotification = ({notification}: {notification: OverdueNotificationType}) => {
  return <>
    <LazyLoadImage src={providerImage(notification.info.providerId)} style={{height: "37.5px", marginBottom: "10px"}} />
    <Typography.P>
      {notification.info.providerName}’s review of your home loan deal is overdue. We have notified them and allowed until the end of today to respond.
    </Typography.P>
  </>
}

const FulfilledNotification = ({notification}: {notification: FulfilledNotificationType}) => {
  if (notification.info.offers.loans.length === 0 || notification.info.offers.loans[0].length === 0) {
    return null
  }
  const totalSavings = notification.info.offers.estimatedSavings.reduce((t,p) => t + p.reduce((st,l) => st + l, 0), 0)
  const firstProduct = notification.info.offers.loans[0][0].product
  return <>
    <div><Typography.P>
      Congratulations! {notification.info.providerName} has reduced your home loan interest rate.
    </Typography.P></div>
    <RenderOfferSummary totalSavings={totalSavings} providerId={notification.info.providerId} providerName={notification.info.providerName} />
    <div><Typography.P>
      Your new rate has already been applied, so you don’t need to do anything else​.
    </Typography.P></div>
    <Clickable.Text secondary noBorder style={{
      backgroundColor: "inherit",
      width: "100%",
      maxWidth: "250px",
      height: "40px",
      marginTop: "10px"
    }} onClick={() => { }}>
      <Typography.P comp="hl-medium-small" fontColor="#00AFC5">
        Learn more
      </Typography.P>
    </Clickable.Text>
    {/* <div><Typography.P>
      TODO: Disclaimers
    </Typography.P></div> */}
  </>
}

const AwaitingCustomerNotification = ({notification}: {notification: AwaitingCustomerNotificationType}) => {
  if (notification.info.offers.loans.length === 0 || notification.info.offers.loans[0].length === 0) {
    return null
  }
  const totalSavings = notification.info.offers.estimatedSavings.reduce((t,p) => t + p.reduce((st,l) => st + l, 0), 0)
  const firstProduct = notification.info.offers.loans[0][0].product
  return <>
    <div><Typography.P>
      Congratulations! {notification.info.providerName} has offered you a new deal on your home loan.
    </Typography.P></div>
    <RenderOfferSummary totalSavings={totalSavings} providerId={notification.info.providerId} providerName={notification.info.providerName} />
    <div><Typography.P>
      TODO: Disclaimers
    </Typography.P></div>
  </>
}

const NoOfferNotification = ({notification}: {notification: NoOfferNotificationType}) => {
  return <>
    <div className={styles.noBetterDeal}>
      <LazyLoadImage src={providerImage(notification.info.providerId)} style={{height: "37.5px", marginBottom: "10px"}} />
      <Typography.P comp="profile-large-medium" fontColor="#202020">
        {notification.info.providerName}
      </Typography.P>
      <Typography.P>
        chose not to offer you a better deal.
      </Typography.P>
    </div>
    <Typography.P comp="profile-large-medium" fontColor="#202020">
      Consider a new lender.
    </Typography.P>
    <div>
      TODO: show refinance option and savings estimate
    </div>
  </>
}

const RefinanceNotification = ({notification}: {notification: RefinanceNotificationType}) => {
  return <>
    <div><Typography.P>
      Meeting booked with Finspo to discuss the {notification.info.providerName} home loan and other refinancing options:
    </Typography.P></div>
    <div>
      TODO: show refinance offer and savings estimate
    </div>
    <div><Typography.P>
      TODO: Disclaimers
    </Typography.P></div>
  </>
}

const ExceptionSituationNotification = ({notification}: {notification: ExceptionSituationNotificationType}) => {
  return <>
    <Typography.P>
      {notification.info.providerName} was unable to verify you using the contact details provided​.<br />
      Please provide alternate contact details that match those held by {notification.info.providerName}.
    </Typography.P>
  </>
}

const ExceptionDetailsNotification = ({notification}: {notification: ExceptionDetailsNotificationType}) => {
  return <>
    <Typography.P>
    Loan details did not match those held by {notification.info.providerName}​.<br />
    Please update and resubmit.
    </Typography.P>
  </>
}

const Notifications = () => {
  const isWide = useMediaQuery("(min-width:768px)")
  const [notifications, setNotifications] = useState<UserNotificationType[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [itemsRemaining, setItemsRemaining] = useState(true)

  const { userDetails, isRefetching, error: authError } = useAuth()
  const userStatus = React.useMemo(
    () => (isRefetching ? "loading" : authError ? "not-permitted" : userDetails ? "permitted" : "loading"),
    [isRefetching, userDetails, authError]
  )

  useEffect(() => {
    if (userDetails) {
      loadUserNotifications()
      /* TEST DATA */
      // setNotifications([
      //   {
      //     type: "OVERDUE",
      //     created: "2024-03-19",
      //     viewed: false,
      //     info: {
      //       providerId: "00000",
      //       providerName: "ProviderName"
      //     }
      //   },
      //   {
      //     type: "IN_PROGRESS",
      //     created: "2024-03-13",
      //     viewed: true,
      //     info: {
      //       providerId: "00000",
      //       providerName: "ProviderName",
      //       expireDate: "2024-03-18"
      //     }
      //   },
      //   {
      //     type: "FULFILLED",
      //     created: "2024-02-06",
      //     viewed: true,
      //     info: {
      //       providerId: "00000",
      //       providerName: "ProviderName",
      //       offers: {
      //         cashback: 200,
      //         estimatedSavings: [[2000]],
      //         fulfilment: "Fulfilled",
      //         loans: [[
      //           {
      //             expiryDate: "2024-02-10",
      //             knowRevertRate: false,
      //             product: {
      //               dateFrom: "",
      //               dateTo: "",
      //               id: 1,
      //               indexRate: 0.087,
      //               indexRateId: "",
      //               loanPurpose: "OWNER_OCCUPIED",
      //               productId: "00000-HL000100",
      //               productName: "Sample Bank Home Loan",
      //               providerId: "00000",
      //               providerName: "ProviderName",
      //               repaymentType: "PRINCIPAL_AND_INTEREST"
      //             },
      //             rate: 0.078,
      //             rateType: "VARIABLE"
      //           }
      //         ]]
      //       }
      //     }
      //   },
      //   {
      //     type: "IN_PROGRESS",
      //     created: "2024-02-05",
      //     viewed: true,
      //     info: {
      //       providerId: "00000",
      //       providerName: "ProviderName",
      //       expireDate: "2024-02-08"
      //     }
      //   },
      //   {
      //     type: "NO_OFFER",
      //     created: "2024-01-01",
      //     viewed: true,
      //     info: {
      //       providerId: "00000",
      //       providerName: "ProviderName"
      //     }
      //   }
      // ])
      // setLoading(false)
      // setItemsRemaining(false)
      /* END TEST */
    }
  }, [userDetails])

  useEffect(() => {
    console.log('notifications:',notifications)
  }, [notifications])

  const onSuccess = (responseJson: any) => {
    if (responseJson) {
      const items = responseJson.items || []
      if (responseJson.count <= (items.length + notifications.length)) {
        setItemsRemaining(false)
      }
      setNotifications([...notifications, ...items.map((n: any) => (n as UserNotificationType))])
      setLoading(false)
    }
  }
  const onError = (error: any) => {
    // if (error.status === 401) {
    //   navigate("/user/sign-in")
    // }
    setError("An error has occurred. Please reload the page.")
    setLoading(false)
  }

  const loadUserNotifications = () => {
    setLoading(true)
    API.getUserNotifications(onSuccess, onError, false, 10, notifications.length || 0)
  }

  return <div className="page-container">
    <div style={{ width: isWide ? "480px" : "90%" }}>
      {userStatus !== "permitted" ? <CircularProgress /> : <>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end" marginBottom={"30px"}>
          <Typography.P comp="profile-large-bold" fontColor="#202020">
            Notifications
          </Typography.P>
          <Clickable.Text
            secondary
            noBorder
            onClick={() => navigate("/user/dashboard")}
          >
            <Typography.P comp="hl-medium-small" fontColor="#00AFC5" style={{padding: "2px 6px"}}>
              Back
            </Typography.P>
          </Clickable.Text>
        </Stack>
        {notifications && notifications.length > 0 && (
          notifications.map((n,i) => <UserNotification notification={n} key={i} />)
        )}
        {loading ? <CircularProgress style={{color: "#00AFC5"}} /> : error ? <Typography.P>
          {error}
        </Typography.P> : itemsRemaining ? <Clickable.Text
          style={{ height: "36px" }}
          onClick={() => {!loading && loadUserNotifications()}}
        >
          <Typography.P comp="medium-bold-tight" fontColor="#FFFFFF">
            Load more
          </Typography.P>
        </Clickable.Text> : (!notifications || notifications.length === 0) ? <Typography.P>
          No notifications to display.
        </Typography.P> : null}
      </>}
    </div>
  </div>
}

export default Notifications